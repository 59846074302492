import React, { CSSProperties, useEffect, useRef } from "react";
import { useGlobalStore } from "../../stores/globalStore";
import { useComputedStyle } from "../../react-components/hooks/useComputedStyle";
import { useStyles } from "../../react-components/hooks/useStyles";
import { useReactWrapper } from "../../react-components/hooks/useReactWrapper";
import classNames from "classnames";

interface ProductBannerCSSProperties extends CSSProperties {
  "--product-banner-offset": string;
}

interface ProductBannerProps {
  title: string;
  description: string;
  containerId?: string;
  color?: "green" | "yellow" | "orange";
}

export const ProductBanner = ({
  title,
  description,
  containerId,
  color = "orange",
}: ProductBannerProps) => {
  const header = useGlobalStore((state) => state.layout.header);
  const { setHeight } = useGlobalStore((state) => state.product.banner);

  const innerRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const wrapper = useReactWrapper(containerId);

  const descriptionStyles = useComputedStyle<HTMLParagraphElement>(descriptionRef);
  const innerStyles = useComputedStyle<HTMLDivElement>(innerRef);

  useStyles<ProductBannerCSSProperties>(
    wrapper,
    {
      "--product-banner-offset": `${header.height}px`,
    },
    [header.height],
  );

  useEffect(() => {
    if (innerStyles && descriptionStyles) {
      setHeight(
        parseInt(innerStyles.height) -
          parseInt(descriptionStyles.height) -
          parseInt(innerStyles.paddingBottom),
      );
    }
  }, [descriptionStyles, innerStyles, setHeight]);

  return (
    <div className={classNames("ProductBanner__component", `ProductBanner__component--${color}`)}>
      <div className="ProductBanner__inner" ref={innerRef}>
        <p>
          <strong>{title}</strong>
        </p>
        <p ref={descriptionRef}>{description}</p>
      </div>
    </div>
  );
};
