import React, { FC, useState } from "react";
import classNames from "classnames";
import { Plus } from "../../Icons/Plus";
import { useIsBelowScreenSize } from "../../useIsBelowScreenSize";
import { LinkBlockGrid } from "../../LinkBlockGrid/LinkBlockGrid";
import { Richtext } from "../../../Partials/RichtextArea/Richtext";
import {
  AnchorNavigation,
  AnchorNavigationItemType,
} from "../../AnchorNavigation/AnchorNavigation";
import { TabSection } from "../TabsListing";
import { LinkArrowBlockList } from "../../LinkArrowBlockList/LinkArrowBlockList";
import { VesselProps } from "../../../Pages/VesselSegmentsLandingPage/VesselListing/VesselProps.csharp";

interface TabSectionsProps {
  sections: TabSection[];
  collapsibleOnMobile?: boolean;
  displayAsList?: boolean;
}

const getAnchors = (sections: TabSection[]): AnchorNavigationItemType[] => {
  return sections.map((section) => ({
    name: section.title,
    id: section.title.replace(/\s+/g, "-").toLowerCase(),
  }));
};

export const TabSections: FC<TabSectionsProps> = ({
  sections,
  collapsibleOnMobile = false,
  displayAsList = false,
}) => {
  const [openedSectionsIds, setOpenedSectionsIds] = useState<string[]>([]);
  const isMobile = useIsBelowScreenSize("tablet");

  const toggleSection = (sectionId: string) => {
    setOpenedSectionsIds((prevState) => {
      if (prevState.includes(sectionId)) {
        return prevState.filter((id) => id !== sectionId);
      } else {
        return prevState.concat(sectionId);
      }
    });
  };

  const anchors = getAnchors(sections);

  return (
    <div className="TabSections">
      <AnchorNavigation
        anchors={anchors}
        bigTouchArea={!collapsibleOnMobile}
        hiddenOnMobile={collapsibleOnMobile}
      />
      {sections.map((section, idx) => {
        const sectionId = anchors.find((anchor) => anchor.name === section.title)?.id || `${idx}`;
        const sectionItemsWrapperId = `${sectionId}_items`;
        const isSectionExpanded = openedSectionsIds.includes(sectionId);

        const titleElement = <h2 className="TabSections__sectionHeading">{section.title}</h2>;

        return (
          <section
            className={classNames("TabSections__section", {
              "TabSections__section--withList": displayAsList,
              "TabSections__section--collapsible": collapsibleOnMobile,
            })}
            key={`${sectionId}_${idx}`}
            id={sectionId}
          >
            {collapsibleOnMobile && (
              <button
                className={classNames(
                  "TabSections__sectionTitle TabSections__sectionTitle--asButton",
                  { "TabSections__sectionTitle--expanded": isSectionExpanded },
                )}
                onClick={() => toggleSection(sectionId)}
                aria-controls={sectionItemsWrapperId}
                aria-expanded={isSectionExpanded}
              >
                {titleElement}
                <Plus />
              </button>
            )}
            <div
              className={classNames("TabSections__sectionTitle TabSections__sectionTitle--asDiv", {
                "TabSections__sectionTitle--hiddenOnMobile": collapsibleOnMobile,
                "TabSections__sectionTitle--noMargin": section.description || displayAsList,
                "TabSections__sectionTitle--withBackground": displayAsList,
                "TabSections__sectionTitle--withBackgroundOnlyMobile":
                  !displayAsList && !collapsibleOnMobile,
                "TabSections__sectionTitle--negativeMarginBottom":
                  !displayAsList && section.description,
              })}
            >
              <div
                className={classNames("TabSections__sectionTitleInner", {
                  "TabSections__sectionTitleInner--withHorizontalPadding": displayAsList,
                })}
              >
                {titleElement}
              </div>
            </div>

            <div
              className={classNames("TabSections__sectionContent", {
                "TabSections__sectionContent--expandable": collapsibleOnMobile,
                "TabSections__sectionContent--expanded": isSectionExpanded,
                "TabSections__sectionContent--noHorizontalMarginOnMobile": displayAsList,
              })}
              id={sectionItemsWrapperId}
            >
              <div className="TabSections__sectionContentInner">
                {section.description && (
                  <Richtext className="TabSections__sectionDescription">
                    {section.description}
                  </Richtext>
                )}
                {displayAsList ? (
                  <LinkArrowBlockList items={section.items} itemTitleTag="h3" />
                ) : (
                  <LinkBlockGrid
                    items={section.items.map((item) => {
                      const i = item as VesselProps;

                      return {
                        model: {
                          title: i.text,
                          description: i.description,
                          linkUrl: i.href,
                          image: i.image,
                          imageProfile: i.imageProfile,
                        },
                        disableFocus: isMobile && collapsibleOnMobile && !isSectionExpanded,
                      };
                    })}
                  />
                )}
              </div>
              {section.readMoreUrl && (
                <div className="TabSections__seeMoreLink">
                  <a href={section.readMoreUrl} className="ButtonSecondary">
                    {section.readMoreLabel ? section.readMoreLabel : "See more"}
                  </a>
                </div>
              )}
              <span
                className={classNames("TabSections__sectionContentPaddingBottom", {
                  "TabSections__sectionContentPaddingBottom--withBackground": displayAsList,
                  "TabSections__sectionContentPaddingBottom--small": displayAsList,
                })}
                aria-hidden
              />
            </div>
          </section>
        );
      })}
    </div>
  );
};
